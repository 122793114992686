/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchQuery: "",
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    resetCommonState: () => initialState,
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
  },
});
export const { resetCommonState, setSearchQuery } = commonSlice.actions;
export default commonSlice.reducer;
